import { Button } from '@teddly/teddly-ui-components';
import styles from './PageNotFound.module.scss';
import classNames from 'classnames';
import { useRoutesContext } from '@context/RoutesContext';
import { getServerAssetsImageUrl } from '@utils';
import useWindowDimensions from '@hooks/useWindowDimension';
import React from 'react';

const NOT_FOUND_TITLE = 'Page not found';
const NOT_FOUND_SUB_TITLE =
  "The page you are looking for doesn't exist anymore. lets get you somewhere else...";

function PageNotFound(): JSX.Element {
  const { navigateTo, pages } = useRoutesContext();
  const { isLargeTablet } = useWindowDimensions();
  const [headerHeight, setHeaderHeight] = React.useState('');
  React.useEffect(() => {
    setHeaderHeight(
      window.getComputedStyle(document.getElementById('page-header'))?.height ||
        (isLargeTablet ? '57px' : '81px'),
    );
  }, [window?.innerHeight]);

  return (
    <div
      className={styles.pageNotFoundContainer}
      style={{ height: `calc(100% - ${headerHeight})` }}>
      <div
        className={styles.backgroundImage}
        style={{
          background: `url(${getServerAssetsImageUrl('city-background.png')})`,
        }}></div>
      <img
        className={classNames(styles.notFoundImage)}
        src={getServerAssetsImageUrl('not-found.png')}
        alt="notFoundImage"
      />
      <div className={styles.bottomContainer}>
        <div className={styles.textContainer}>
          <div className={styles.notFoundTitle}>{NOT_FOUND_TITLE}</div>
          <div className={styles.notFoundSubTitle}>{NOT_FOUND_SUB_TITLE}</div>
        </div>
        <Button
          title="Go to home page"
          onClick={() => {
            navigateTo({ route: pages.HOME });
          }}
        />
      </div>
    </div>
  );
}

export default PageNotFound;
